exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-drcatalina-tsx": () => import("./../../../src/pages/about/drcatalina.tsx" /* webpackChunkName: "component---src-pages-about-drcatalina-tsx" */),
  "component---src-pages-about-expectations-tsx": () => import("./../../../src/pages/about/expectations.tsx" /* webpackChunkName: "component---src-pages-about-expectations-tsx" */),
  "component---src-pages-about-naturopathic-tsx": () => import("./../../../src/pages/about/naturopathic.tsx" /* webpackChunkName: "component---src-pages-about-naturopathic-tsx" */),
  "component---src-pages-conditions-autoimmune-tsx": () => import("./../../../src/pages/conditions/autoimmune.tsx" /* webpackChunkName: "component---src-pages-conditions-autoimmune-tsx" */),
  "component---src-pages-conditions-cardiometabolic-tsx": () => import("./../../../src/pages/conditions/cardiometabolic.tsx" /* webpackChunkName: "component---src-pages-conditions-cardiometabolic-tsx" */),
  "component---src-pages-conditions-gastrointestinal-tsx": () => import("./../../../src/pages/conditions/gastrointestinal.tsx" /* webpackChunkName: "component---src-pages-conditions-gastrointestinal-tsx" */),
  "component---src-pages-conditions-hormone-tsx": () => import("./../../../src/pages/conditions/hormone.tsx" /* webpackChunkName: "component---src-pages-conditions-hormone-tsx" */),
  "component---src-pages-conditions-mentalhealth-tsx": () => import("./../../../src/pages/conditions/mentalhealth.tsx" /* webpackChunkName: "component---src-pages-conditions-mentalhealth-tsx" */),
  "component---src-pages-conditions-preventative-tsx": () => import("./../../../src/pages/conditions/preventative.tsx" /* webpackChunkName: "component---src-pages-conditions-preventative-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-eyecream-tsx": () => import("./../../../src/pages/products/eyecream.tsx" /* webpackChunkName: "component---src-pages-products-eyecream-tsx" */),
  "component---src-pages-services-advancedlabs-tsx": () => import("./../../../src/pages/services/advancedlabs.tsx" /* webpackChunkName: "component---src-pages-services-advancedlabs-tsx" */),
  "component---src-pages-services-foundations-tsx": () => import("./../../../src/pages/services/foundations.tsx" /* webpackChunkName: "component---src-pages-services-foundations-tsx" */),
  "component---src-pages-services-hair-tsx": () => import("./../../../src/pages/services/hair.tsx" /* webpackChunkName: "component---src-pages-services-hair-tsx" */),
  "component---src-pages-services-herbal-tsx": () => import("./../../../src/pages/services/herbal.tsx" /* webpackChunkName: "component---src-pages-services-herbal-tsx" */),
  "component---src-pages-services-ivtherapy-tsx": () => import("./../../../src/pages/services/ivtherapy.tsx" /* webpackChunkName: "component---src-pages-services-ivtherapy-tsx" */),
  "component---src-pages-services-nutrition-tsx": () => import("./../../../src/pages/services/nutrition.tsx" /* webpackChunkName: "component---src-pages-services-nutrition-tsx" */),
  "component---src-pages-services-prp-tsx": () => import("./../../../src/pages/services/prp.tsx" /* webpackChunkName: "component---src-pages-services-prp-tsx" */)
}

